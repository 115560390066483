import { css, jsx } from '@emotion/core';
import { Icon } from 'antd';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import CustomIcon from '@/src/shared/components/Icon';
import { bp, colors, easing } from '@/styles';

const DefaultImagePath = `${process.env.API_ROOT}/static/images/IndustryIssues_Default_350x350.png`;

const styles = {
  a: css`
    pointer-events: none;
  `,
  cardsContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media print {
      margin: 0 !important;
    }
  `,
  buttonContainer: css`
    margin-top: 40px;
    max-width: 768px;
    width: 100%;
    display: none;
    @media ${bp.md} {
      display: block;
    }
  `,
  card: (fullWidth, disabled) => css`
    display: flex;
    background-color: ${colors.white};
    max-width: ${fullWidth ? '100%' : '768px'};
    width: 100%;
    padding: 16px 20px;
    box-shadow: 0 6px 12px 0 rgba(35, 32, 32, 0.1);
    border-radius: 2px;
    pointer-events: ${disabled ? 'none' : 'auto'};
    transition-timing-function: ${easing.easeInOut};
    transition-duration: 0.3s;
    transition-property: box-shadow, transform;
    &:hover {
      box-shadow: 0 11px 12px 0 rgba(35, 32, 32, 0.15);
      transform: translateY(-1px);
    }
    &:nth-of-type(2n) {
      margin: 25px 0;
    }
    &:first-of-type {
      margin: 0px 0px 0px;
    }
    &:last-of-type {
      margin: 25px 0px 25px;
    }
    @media ${bp.md} {
      padding: 20px 0px;
    }
    @media print {
      box-shadow: none;
    }
  `,
  cardSideContainer: (width = '120px') => css`
    width: ${width};
    justify-content: center;
    align-items: center;
    display: none;
    @media ${bp.md} {
      display: flex;
    }
    @media print {
      display: block;
      width: auto;
    }
  `,
  cardImage: css`
    font-size: 5.2rem;
    color: ${colors.secondaryBlue};
    margin: 0px 32px;
    @media print {
      font-size: 2.6rem;
      margin: 0 32px 0 0;
    }
  `,
  cardIcon: css`
    font-size: 2.4rem;
    color: ${colors.secondaryBlue};
  `,
  cardIconRight: css`
    font-size: 2.4rem;
    color: ${colors.secondaryBlue};
    @media print {
      display: none;
    }
  `,
  cardTextContainer: css`
    width: 100%;
    p {
      margin-bottom: 0;
    }
  `,
  cardTitle: css`
    color: ${colors.primaryBlue};
    font-size: 1.6rem;
    line-height: 2.8rem;
  `,
  cardTimestamp: css`
    color: ${colors.secondaryGray};
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-size: 1.4rem;
    font-weight: 600;
  `,
  contentLink: css`
    font-size: 14px;
    font-weight: 700;
    padding-right: 5px;
    margin: 0 0 0 auto;
    vertical-align: middle;
    float: right;
    .anticon {
      margin: 0 0 0 3px;
    }
  `,
  defaultImage: css`
    font-size: 8rem;
    color: ${colors.secondaryBlue};
    margin: 0px 30px 0px 0px;
    @media print {
      font-size: 2.6rem;
      margin: 0 32px 0 0;
    }
  `,
  image: css`
    width: 150px;
    object-fit: cover;
    padding-right: 20px;
    margin-left: -8px;
    display: none;
    @media ${bp.sm} {
      display: block;
    }
  `,
  viewButton: css`
    width: 100%;
    max-width: 343px;
    margin: 24px 16px 0px;
    height: 43px !important;
    display: block;
    @media ${bp.md} {
      display: none;
    }
  `,
  summary: css`
    font-size: 1.6rem;
    line-height: 2.8rem;
  `,
  truncateSummary: css`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
  noIcon: css`
    @media ${bp.md} {
      padding-left: 32px;
    }
  `,
};

const ResultCard = memo(props => {
  const {
    truncate,
    summaryLimit,
    as,
    href,
    iconId,
    title,
    subtitle,
    summary,
    fullWidth,
    disabled = false,
    iconVisible,
    learnMoreLink = false,
    imageSource,
  } = props;

  function renderSummary() {
    if (!truncate) return summary;
    return (summary || '').length > summaryLimit
      ? `${summary.substring(0, summaryLimit)}...`
      : summary;
  }
  const cardInner = (
    <>
      {iconVisible && !learnMoreLink ? (
        <div css={styles.cardSideContainer()}>
          <CustomIcon css={styles.cardImage} id={iconId} />
        </div>
      ) : (
        <div css={styles.noIcon} />
      )}
      {learnMoreLink && imageSource && (
        <div css={styles.image}>
          <img src={imageSource} alt="Content Group" />
        </div>
      )}
      {learnMoreLink && !imageSource && (
        <div css={styles.image}>
          <img src={DefaultImagePath} alt="Content Group" />
        </div>
      )}
      <div css={styles.cardTextContainer}>
        {subtitle ? <div css={styles.cardTimestamp}>{subtitle}</div> : null}
        <p css={styles.cardTitle}>
          <strong>{title}</strong>
        </p>
        <p css={styles.summary && learnMoreLink ? styles.truncateSummary : null}>
          {renderSummary()}
        </p>
      </div>
      {disabled ? (
        <div css={styles.noIcon} />
      ) : (
        <div css={styles.cardSideContainer('88px')}>
          <Icon css={styles.cardIconRight} type="right" />
        </div>
      )}
    </>
  );

  return disabled ? (
    <div css={styles.card(fullWidth, disabled)}>{cardInner}</div>
  ) : (
    <Link href={href} as={as} passHref>
      <a css={styles.card(fullWidth, disabled)}>{cardInner}</a>
    </Link>
  );
});

ResultCard.propTypes = {
  truncate: PropTypes.bool,
  href: PropTypes.string,
  iconId: PropTypes.number,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  summary: PropTypes.string,
  summaryLimit: PropTypes.number,
  fullWidth: PropTypes.bool,
  iconVisible: PropTypes.bool,
};

ResultCard.defaultProps = {
  href: '',
  truncate: true,
  iconId: null,
  subtitle: null,
  summary: '',
  summaryLimit: 115,
  fullWidth: false,
  iconVisible: true,
};

export default ResultCard;
